import React, { useState } from "react";
import PrimaryButton from "../Buttons/Primary/PrimaryButton";
import "./AddNewHotspot.scss";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import HotspotPopup from "../HotspotPopup/HotspotPopup";
import { useSelector } from "react-redux";
import { selectModelViewer } from "../../store/modelViewer/reducer";
import { AnimatePresence, motion } from "framer-motion";

export default function AddNewHotspot() {
  const [showPopup, setShowPopup] = useState(false);

  const { topAction } = useSelector(selectModelViewer);

  return (
    <AnimatePresence>
      {topAction === "explore" && (
        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
          }}
          exit={{
            scale: 0,
          }}
          className="add-new-hotspot"
        >
          <PrimaryButton
            onClick={() => setShowPopup(!showPopup)}
            className="add-new-hotspot__button"
          >
            <div className="new-hotspot-text">
              <span>New Hotspot</span>
              <PlusIcon />
            </div>
          </PrimaryButton>
          <HotspotPopup active={showPopup} />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
