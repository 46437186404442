import React from "react";
import { ReactComponent as CheckSuccessIcon } from "../../../assets/icons/checksuccess.svg";
import { AnimatePresence, motion } from "framer-motion";
export default function CopySuccess({ show }) {
  return (
    <AnimatePresence exitBeforeEnter>
      {show && (
        <motion.div
          initial={{
            transform: "translate(-50%, -50%) scale(0)",
          }}
          animate={{
            transform: "translate(-50%, -50%) scale(1)",
          }}
          exit={{
            transform: "translate(-50%, -50%) scale(0)",
          }}
          transition={{ type: "spring", duration: 0.6 }}
          className="copy-success"
        >
          <CheckSuccessIcon /> <span>Copied to Clipboard!</span>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
