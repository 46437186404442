import React, { useState } from "react";
import HotspotContent from "./HotspotContent/HotspotContent";

import "./HotspotPopup.scss";
import HotspotSettings from "./HotspotSettings";
import HotspotTabs from "./HotspotTabs";
import { AnimatePresence, motion } from "framer-motion";
const tabs = [
  {
    label: "Hotspot Settings",
    id: "hotspot-settings",
  },
  {
    label: "Hotspot Content",
    id: "hotspot-content",
  },
];
export default function HotspotPopup({ active }) {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const onChangeHandler = (value) => {
    setActiveTab(value);
  };

  return (
    <AnimatePresence>
      {active && (
        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
          }}
          exit={{
            scale: 0,
          }}
          className={"hotspot-popup"}
        >
          <HotspotTabs
            tabs={tabs}
            active={activeTab}
            onActiveChange={onChangeHandler}
          />

          <div className="hotspot-popup__tabcontent">
            {activeTab === "hotspot-settings" ? (
              <HotspotSettings />
            ) : (
              <HotspotContent />
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
