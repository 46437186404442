import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/Primary/PrimaryButton";
import SearchInput from "../../components/Inputs/Search/SearchInput";
import FilterSelector from "../../components/FilterSelector/FilterSelector";
import ConfiguratorTable from "../../components/ConfiguratorTable/ConfiguratorTable";

import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";

import "./ConfiguratorScreen.scss";
import Pagination from "../../components/Pagination/Pagination";

export default function ProductScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch();
  // const [loader, setLoader] = useState(false);

  // const stopLoader = () => {
  //   setLoader(false);
  // };
  // useEffect(() => {
  //   setLoader(true);

  //   dispatch(getAllProducts(stopLoader));
  // }, [dispatch]);

  // if (loader) {
  //   return (
  //     <div className="abs-center">
  //       <Loading />
  //     </div>
  //   );
  // }
  return (
    <main className="configurator-main">
      <div className="configurator-wrapper">
        <SearchInput placeholder="Search 3D viewer" />
        <PrimaryButton
          className="create-new-btn"
          onClick={() => navigate("new", { state: { background: location } })}
        >
          <div className="new-btn-text">
            <PlusIcon />
            <span>Create configure</span>
          </div>
        </PrimaryButton>
      </div>

      <div className="configurator-header">
        <h3>3D Viewer</h3>
        <FilterSelector title="Sort by" name="sort-by" />
      </div>
      <div className="table-wrapper">
        <ConfiguratorTable />

        <Pagination limit={5} totalPages={40} />
      </div>
    </main>
  );
}
