import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import "./CopyLink.scss";
export default function CopyLink({ link }) {
  const [copied, setCopied] = useState(false);
  return (
    <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
      <button className="copy-container">
        <LinkIcon />
        <p>{copied ? "Copied!" : "Copy the link"}</p>
      </button>
    </CopyToClipboard>
  );
}
