import React from "react";
import "./ConfiguratorTable.scss";
import ConfiguratorTableRow from "./ConfiguratorTableRow";

const configurator = [
  {
    poster: "/Rectangle.jpg",
    name: "Viewer name",
    hide: false,
    status: "Published",
  },
  {
    poster: "/Rectangle.jpg",
    name: "Viewer name",
    hide: false,
    status: "Published",
  },
  {
    poster: "/Rectangle.jpg",
    name: "Viewer name",
    hide: false,
    status: "Published",
  },
];
export default function ConfiguratorTable() {
  return (
    <table className="configurator-table">
      <thead className="table-header">
        <tr>
          <th>Poster</th>
          <th className="col-expand">Name</th>
          <th>hide</th>

          <th>status</th>
          <th>actions</th>
        </tr>
      </thead>
      <tbody className="table-body">
        {configurator.map((product) => {
          return <ConfiguratorTableRow item={product} key={product.uuid} />;
        })}
      </tbody>
    </table>
  );
}
