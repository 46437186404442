import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
export default function NewVarientItem({ imgUrl, active, onChange }) {
  return (
    <li className={"variants-slider__item " + (active ? "active" : "")}>
      <button onClick={onChange}>
        <img src={imgUrl} alt={"item"} />
        <button>
          <CloseIcon />
        </button>
      </button>
    </li>
  );
}
