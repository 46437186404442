import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

import { AnimatePresence, motion } from "framer-motion";
export default function HotspotContent({ onClose, show }) {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{
            opacity: 0,
            transform: "translate(-105%, -70%)",
          }}
          animate={{
            opacity: 1,
            transform: "translate(-105%, -50%)",
          }}
          exit={{
            opacity: 0,
            transform: "translate(-105%, -30%)",
          }}
          transition={{
            duration: 0.3,
          }}
          className="hotspot__content"
        >
          <button onClick={onClose} className="hotspot__content__close">
            <CloseIcon />
          </button>
          <img src="https://picsum.photos/200/300" alt="abc" />
          <h1>Retina display</h1>

          <p>
            13.3-inch (diagonal) LED-backlit display with IPS technology;
            2560x1600 native resolution at 227 pixels per inch
          </p>
          <p>500 nits brightness</p>
          <p>Wide colour (P3)</p>
          <p>True Tone technology</p>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
