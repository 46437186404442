import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/Primary/PrimaryButton";
import SearchInput from "../../components/Inputs/Search/SearchInput";
import FilterSelector from "../../components/FilterSelector/FilterSelector";
import ProductsTable from "../../components/ProductsTable/ProductsTable";
import { useDispatch } from "react-redux";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";

import "./ProductsScreen.scss";
import { useEffect, useState } from "react";
import { getAllProducts } from "../../store/dashboard/actions";
import Loading from "../../components/Loading/Loading";

export default function ProductScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const stopLoader = () => {
    setLoader(false);
  };
  useEffect(() => {
    setLoader(true);

    dispatch(getAllProducts(stopLoader));
  }, [dispatch]);

  if (loader) {
    return (
      <div className="abs-center">
        <Loading />
      </div>
    );
  }
  return (
    <main className="product-main">
      <div className="products-wrapper">
        <SearchInput placeholder="Search Product" />
        <PrimaryButton
          className="create-new-btn"
          onClick={() => navigate("new", { state: { background: location } })}
        >
          <div className="new-btn-text">
            <PlusIcon />
            <span>Create product</span>
          </div>
        </PrimaryButton>
      </div>

      <div className="products-header">
        <h3>Products</h3>
        <FilterSelector title="Sort by" name="sort-by" />
      </div>
      <div className="table-wrapper">
        <ProductsTable />
      </div>
    </main>
  );
}
