import React, { useState } from "react";
import HotspotContentBottom from "./HotspotContentBottom";
import HotspotContentTop from "./HotspotContentTop";

export default function HotspotContent() {
  const [activeTab, setActiveTab] = useState("vedio-tab");

  return (
    <div>
      <div className="area-box">
        <HotspotContentTop
          value={activeTab}
          onChange={(val) => setActiveTab(val)}
        />
        <div className="area-box__bottom">
          <HotspotContentBottom activeTab={activeTab} />
        </div>
      </div>
    </div>
  );
}
