import { useEffect, useState } from "react";

import ActionButtonStandard from "../Action/ActionButtonStandard";
import { ReactComponent as FullscreenIcon } from "../../../assets/icons/maximize.svg";
import { ReactComponent as CloseFullscreenIcon } from "../../../assets/icons/closeFullscreen.svg";

export default function FullscreenButton() {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const icon = isFullscreen ? CloseFullscreenIcon : FullscreenIcon;

  const fullscreenAvailable =
    document.fullscreenEnabled ||
    document.msFullScreenEnable ||
    document.webkitFullscreenEnabled;

  function enableFullscreen() {
    document.body.requestFullscreen && document.body.requestFullscreen();
    document.body.msRequestFullscreen && document.body.msRequestFullscreen();
    document.body.webkitRequestFullscreen &&
      document.body.webkitRequestFullscreen();
  }

  function exitFullscreen() {
    document.exitFullscreen && document.exitFullscreen();
    document.msExitFullscreen && document.msExitFullscreen();
    document.webkitExitFullscreen && document.webkitExitFullscreen();
  }

  const toggleFullscreen = () => {
    isFullscreen ? exitFullscreen() : enableFullscreen();
    setIsFullscreen(!isFullscreen);
  };

  function handleFullscreenChange() {
    document.fullscreenElement ? setIsFullscreen(true) : setIsFullscreen(false);
  }

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return function cleanup() {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  return (
    fullscreenAvailable && (
      <ActionButtonStandard
        className="fullscreen-button"
        Icon={icon}
        handleClick={toggleFullscreen}
      />
    )
  );
}
