import { useSelector } from "react-redux";
import { selectAuth } from "../../store/auth/reducer";
export default function OverviewScreen() {
  const { user } = useSelector(selectAuth);

  return (
    <main className="overview-screen">
      <h2>Overview</h2>
      <p>Logged in: {user?.username}</p>
      <p>User ID: {user?.attributes.sub}</p>
    </main>
  );
}
