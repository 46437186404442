import { ReactComponent as ArIcon } from "../../../assets/icons/ar.svg";
import ActionButtonViewerState from "../Action/ActionButtonViewerState";

export default function ArButton() {
  function handleClick() {
    const modelViewer = document.querySelector("model-viewer");

    if (modelViewer.canActivateAR) {
      modelViewer.cameraOrbit = "0deg 75deg 105%";
      modelViewer.cameraTarget = "auto auto auto";
      modelViewer.resetTurntableRotation();
      setTimeout(() => modelViewer.activateAR(), 500);
    } else {
    }
  }

  return (
    <ActionButtonViewerState
      active={true}
      label="AR"
      action="ar"
      Icon={ArIcon}
      handleClick={handleClick}
    />
  );
}
