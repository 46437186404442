import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import "./Tag.scss";
export default function Tag({ tag, onDelete }) {
  const onClickHandler = () => {
    onDelete(tag.id);
  };
  return (
    <div className="tag-container">
      <label>{tag.name}</label>
      <button onClick={onClickHandler}>
        <CloseIcon />
      </button>
    </div>
  );
}
