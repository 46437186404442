import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { selectModelViewer } from "../../store/modelViewer/reducer";
import NewVarientItem from "./NewVarientItem";
import "./NewVarientsSlider.scss";
export default function NewVarientsSlider() {
  const { topAction } = useSelector(selectModelViewer);
  return (
    <AnimatePresence>
      {topAction === "select" && (
        <motion.div
          initial={{
            transform: "translateY(100%) rotateX(180deg)",
            opacity: 0,
          }}
          animate={{
            transform: "translateY(0%) rotateX(180deg)",
            opacity: 1,
          }}
          exit={{
            transform: "translateY(100%) rotateX(180deg)",
            opacity: 0,
          }}
          className="variants-slider"
        >
          <ul
            className="variants-slider__track"
            style={{
              width: 160 * 3,
            }}
          >
            <NewVarientItem active={true} imgUrl="/chair.png" />
            <NewVarientItem imgUrl="/chair.png" />

            <NewVarientItem imgUrl="/chair.png" />
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
