import React, { useEffect, useRef, useState } from "react";
import "./SelectWithLabel.scss";
import { BlockPicker } from "react-color";
import Switch from "react-switch";
const InputType = ({
  type,
  options = [],
  value,
  onChange,
  name,
  className,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const buttonRef = useRef();

  const colorPickerToggleHandler = (e) => {
    setShowPicker(!showPicker);
  };
  useEffect(() => {
    const clickHandler = (e) => {
      const node = buttonRef.current;

      if (
        !node?.contains(e.target) &&
        !e.target?.closest(".picker-box")?.classList?.contains("picker-box")
      ) {
        setShowPicker(false);
      }
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [buttonRef, name]);
  switch (type) {
    case "select":
      return (
        <select
          className={className}
          name={name}
          onChange={onChange}
          value={value}
        >
          <option value={""}>none</option>

          {options.map((option) => (
            <option value={option?.value}>{option?.label}</option>
          ))}
        </select>
      );
    case "colorPicker":
      return (
        <div className="colorPicker-container">
          <button
            ref={buttonRef}
            className={className}
            id={name}
            onClick={colorPickerToggleHandler}
          >
            <span></span> #ffffff
          </button>
          {showPicker && (
            <BlockPicker
              className="picker-box"
              color={value}
              onChange={onChange}
            />
          )}
        </div>
      );
    case "switch":
      return (
        <Switch
          onChange={onChange}
          checked={value}
          onColor="#18a0fb"
          uncheckedIcon={null}
          checkedIcon={null}
          draggable={false}
          height={20}
          width={40}
        />
      );
    default:
      return (
        <div className="range-container">
          <input
            className={className}
            type={type}
            name={name}
            onChange={onChange}
            value={value}
            max={100}
            min={0}
          />
          {type === "range" && value + "%"}
        </div>
      );
  }
};

export default function SelectWithLabel({
  type,
  options,
  value,
  onChange,
  label,
  name,
  Icon,
}) {
  return (
    <div className="select-with-label">
      <label>
        {Icon && <Icon />} {label}
      </label>
      <InputType
        type={type}
        options={options}
        value={value}
        name={name}
        onChange={onChange}
      />
    </div>
  );
}
