import React, { useEffect, useState } from "react";
import "./ProductDetailTabs.scss";

export default function ProductDetailTabs({ tabs, children }) {
  const [activeId, setActiveId] = useState(tabs?.[0]?.id);

  const activeHandler = (id) => () => {
    setActiveId(id);
  };
  useEffect(() => {
    if (activeId) {
      const elements = document.querySelectorAll(`.tab[data-tab]`);
      elements.forEach((e) => {
        if (e.dataset.tab === activeId) {
          e.classList.add("active");
        } else {
          e.classList.remove("active");
        }
      });
    }
  }, [activeId]);
  return (
    <div className="tabs-wrapper">
      <div className="tab-header">
        {tabs.map((tab) => (
          <button
            key={tab?.id}
            className={activeId === tab.id ? "active" : ""}
            onClick={activeHandler(tab.id)}
          >
            {tab?.label}
          </button>
        ))}
      </div>
      <div className="tab-wrapper">{children}</div>
    </div>
  );
}

export const TabHeader = ({ children }) => {
  return <div className="tab__header">{children}</div>;
};

export const TabBody = ({ children }) => {
  return <div className="tab__body">{children}</div>;
};
export const TabFooter = ({ children }) => {
  return <div className="tab_footer">{children}</div>;
};
export const Tab = ({ children, dataTab }) => {
  return (
    <div className="tab" data-tab={dataTab}>
      {children}
    </div>
  );
};
