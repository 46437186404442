import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import dashboardReducer from "./dashboard/reducer";
import modelViewerReducer from "./modelViewer/reducer";

import { configureStore } from "@reduxjs/toolkit";

import thunk from "redux-thunk";

// combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  modelViewer: modelViewerReducer,
});

// setup Store
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
  devTools: process.env.NODE_ENV !== "production",
});
