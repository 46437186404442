import "./ModelViewer.scss";
import "@google/model-viewer";

import { useEffect, useRef, useState } from "react";
import Hotspot from "../Hotspot/Hotspot";
import { useSelector } from "react-redux";
import { selectModelViewer } from "../../store/modelViewer/reducer";

function ModelViewer() {
  const modalRef = useRef(null);
  const [hotspot, setHotspot] = useState(null);
  const { topAction } = useSelector(selectModelViewer);
  useEffect(() => {
    const node = modalRef.current;

    const getPositionAndNormal = (event) => {
      setHotspot(node.positionAndNormalFromPoint(event.clientX, event.clientY));
    };

    if (node && !hotspot) {
      document.addEventListener("click", getPositionAndNormal);
    }
    return () => document.removeEventListener("click", getPositionAndNormal);
  }, [modalRef, hotspot]);
  return (
    <div
      className="reydar-model-viewer"
      allowFullScreen
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
    >
      <model-viewer
        alt="Reydar Model Viewer"
        src={"/8f5f9f71-da76-4757-9ae1-310e6ba3d3dd.glb"}
        ar
        ref={modalRef}
        ar-modes="webxr scene-viewer quick-look"
        ar-scale="fixed"
        poster=""
        seamless-poster
        shadow-intensity="1"
        camera-controls
        enable-pan
        touch-action="none"
        interaction-policy={"always-allow"}
        auto-rotate={true}
        rotation-per-second={"100%"}
        interaction-prompt-style={"wiggle"}
      >
        <button slot="ar-button" style={{ display: "none" }}></button>
        {topAction === "explore" && hotspot && <Hotspot hotspot={hotspot} />}
      </model-viewer>
    </div>
  );
}

export default ModelViewer;
