import React from "react";
import "./ProductsTable.scss";
import ProductTableRow from "./ProductTableRow";
import { useSelector } from "react-redux";
import { selectDashboard } from "../../store/dashboard/reducer";
export default function ProductsTable() {
  const { products } = useSelector(selectDashboard);
  return (
    <table className="products-table">
      <thead className="table-header">
        <tr>
          <th>Poster</th>
          <th className="col-expand">Name</th>
          <th>status</th>
          <th>actions</th>
        </tr>
      </thead>
      <tbody className="table-body">
        {products.map((product) => {
          return (
            <ProductTableRow
              id={product.uuid}
              key={product.uuid}
              poster="/Rectangle.jpg"
              name={product.name}
              status="Published"
            />
          );
        })}
      </tbody>
    </table>
  );
}
