import { useDispatch, useSelector } from "react-redux";
import { updateTopAction } from "../../../store/modelViewer/actions";
import { selectModelViewer } from "../../../store/modelViewer/reducer";
import "./ActionButton.scss";

const ActionButtonViewerState = ({
  className,
  label,
  Icon,
  disabled,
  action,
}) => {
  const dispatch = useDispatch();
  const { topAction } = useSelector(selectModelViewer);
  const updateAction = () => {
    dispatch(updateTopAction(action));
  };
  return (
    <button
      className={
        "action-btn " +
        (topAction === action ? "action-btn-active " : "") +
        (className && className)
      }
      onClick={updateAction}
      disabled={disabled}
    >
      {Icon && <Icon className="action-btn__icon" />}
      <span className="action-btn__label">{label}</span>
    </button>
  );
};

export default ActionButtonViewerState;
