import React, { useEffect, useState } from "react";
import "./Checkbox.scss";
export default function Checkbox({ name, checked, onChange, label }) {
  const [initial, setInitial] = useState(false);
  const onChangeHandler = (e) => {
    setInitial(e.target.checked);
    onChange?.(e.target.checked);
  };
  useEffect(() => {
    setInitial(checked);
  }, [checked]);

  return (
    <label class="checkbox-input">
      {label}
      <input
        name={name}
        type="checkbox"
        checked={initial}
        onChange={onChangeHandler}
      />
      <span class="checkbox-input__checkmark"></span>
    </label>
  );
}
