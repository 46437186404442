import Modal from "../../components/Modal/Modal";
import UploadModelForm from "../../components/Forms/UploadModel/UploadModelForm";

export default function NewProductScreen({ canShow }) {
  return (
    <Modal canShow={true}>
      <UploadModelForm />
    </Modal>
  );
}
