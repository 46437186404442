import React, { useState, useEffect } from "react";
import "./AppLayout.scss";
import Navigation from "../../components/Navigation/Navigation";
import Header from "../../components/Header/Header";

export default function AppLayout({ children }) {
  const [navIsOpen, setNavIsOpen] = useState();
  const [size, setSize] = useState(window.innerWidth);

  useEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);

    if (size < 992) {
      setNavIsOpen(false);
    } else {
      setNavIsOpen(true);
    }
    return () => window.removeEventListener("resize", updateSize);
  }, [size]);

  const handelClick = (e) => {
    const isNavLink = e.target.closest(".drawer-item");

    if (size > 992 && isNavLink?.contains(e.target)) return;

    setNavIsOpen(!navIsOpen);
  };

  return (
    <div className="app-wrapper">
      
      <div className="app-content">
        <Header handelClick={handelClick} />
        <div className="inner-content">{children}</div>
      </div>
      <Navigation navIsOpen={navIsOpen} handelClick={handelClick} />
    </div>
  );
}
