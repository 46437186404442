import React, { useState } from "react";
import Toggle from "../Toggle/Toggle";
import "./ProductDescription.scss";
import { ReactComponent as DotsIcon } from "../../assets/icons/dots.svg";
import Tags from "../Tags/Tags";

export default function ProductDescription({ product }) {
  const [tags, setTags] = useState([]);
  const addTagHandle = (e) => {
    const { value } = e.target;
    setTags([...tags, { name: value, id: tags.length + 1 }]);
  };
  return (
    <div className="product-description_container">
      <div className=" product-label-container">
        <div className="product-label-box">
          <img src={product?.image} alt={product?.name} />
          <label>{product?.name}</label>
        </div>
        <div className="product-status">
          <p>{product?.status || "Draft"} </p>
        </div>
        <Toggle Icon={<DotsIcon />} />
      </div>
      <div className="product-description">
        <label>Description</label>
        <textarea defaultValue={product?.description} rows={3} />
      </div>
      <div className="product-description">
        <label>Tags </label>
        <Tags tags={tags} onInputChange={addTagHandle} />
      </div>
    </div>
  );
}
