import React, { useState } from "react";

import { ReactComponent as ShareIcon } from "../../assets/icons/share.svg";
import { ReactComponent as DotsIcon } from "../../assets/icons/dots.svg";

import Switch from "react-switch";
import Toggle from "../Toggle/Toggle";
import Modal from "../Modal/Modal";
import CopyLink from "../CopyLink/CopyLink";
function ProductTableRow({ item }) {
  const [show, setShow] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);

  return (
    <tr>
      <td>
        <img className="poster-img" src={item?.poster} alt="name of product" />
      </td>
      <td className="col-expand">
        <a href={`/model-viewer/`}>{item?.name}</a>
      </td>
      <td>
        <div>
          <Switch
            onColor="#18a0fb"
            uncheckedIcon={null}
            checkedIcon={null}
            draggable={false}
            onChange={(checked) => setShow(checked)}
            checked={show}
          />
        </div>
      </td>
      <td>
        <div className="product-status">
          <p>{item?.status}</p>
        </div>
      </td>
      <td>
        <div className="configurator-actions">
          <Toggle Icon={<ShareIcon />}>
            <div className="action-menu">
              <button onClick={() => setShowLinkModal(true)}>
                Share configure
              </button>
            </div>
          </Toggle>

          <Toggle Icon={<DotsIcon />}>
            <div className="action-menu">
              <button>Delete product</button>
            </div>
          </Toggle>
        </div>
      </td>
      {showLinkModal && (
        <Modal canShow={showLinkModal} onClose={() => setShowLinkModal(false)}>
          <CopyLink link={"sfjsdhfs"} />
        </Modal>
      )}
    </tr>
  );
}

export default ProductTableRow;
