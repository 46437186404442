import { Storage } from "aws-amplify";

class DashboardApi {
  constructor() {
    Storage.configure({
      customPrefix: {
        protected: "users/",
      },
    });
  }
  getS3ObjectKeys = async () => {
    return await Storage.list("products/data", { level: "protected" });
  };
  getProductsData = async (objects) => {
    const DataRes = await Promise.all(
      objects.map(async (object) => {
        const key = object.key;
        try {
          const rowData = await Storage.get(key, {
            download: true,
            level: "protected",
          });
          const text = await new Response(rowData.Body).json();

          return text;
        } catch (error) {
          return null;
        }
      })
    );

    return DataRes.filter((e) => e);
  };

  getProductDataById = async (id) => {
    const rowData = await Storage.get(`products/data/${id}.json`, {
      download: true,
      level: "protected",
    });
    const text = await new Response(rowData.Body).json();

    return text;
  };

  getProductModalsListByVariantId = async (productId, variantId) => {
    const data = await Storage.list(
      `products/models/${productId}/${variantId}`,
      { level: "protected" }
    );
    return data;
  };

  getModelByKey = async (key) => {
    const rowData = await Storage.get(key, {
      level: "protected",
    });

    return rowData;
  };

  deleteDataById = async (key) => {
    const rowData = await Storage.remove(key, {
      level: "protected",
    });

    return rowData;
  };
}

export default DashboardApi;
