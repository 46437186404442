import React from "react";
import SelectWithLabel from "../Inputs/SelectWithLabel/SelectWithLabel";

export default function HotspotSettings() {
  return (
    <>
      <div className="area-box">
        <span>W 32</span>
        <span>h 32</span>
      </div>
      <div className="hotspot-popup__inner">
        <SelectWithLabel label={"Icon"} type="select" />
        <SelectWithLabel
          label={"Bg color"}
          name={"Bg color"}
          type="colorPicker"
        />
        <SelectWithLabel label={"Stroke"} name={"Stroke"} type="colorPicker" />
        <SelectWithLabel label={"Str. size, px"} />
      </div>
      <div className="hotspot-popup__inner">
        <h1>Settings</h1>
        <SelectWithLabel type="select" label={"Position"} />
        <SelectWithLabel
          label={"Bg color"}
          name={"Bg color"}
          type="colorPicker"
        />
        <SelectWithLabel
          label={"Corner radius"}
          name={"Corner radius"}
          type="colorPicker"
        />
        <SelectWithLabel label={"Shadow Blur"} type="range" value={10} />
        <SelectWithLabel label={"Width"} />
      </div>
    </>
  );
}
