import React from "react";
import Tab from "../Tab/Tab";
import "./ProductsTabs.scss";
export default function ProductsTabs() {
  return (
    <div className="products-tabs">
      <h1>Products</h1>
      <div className="products-tabs__container">
        <div className="products-tabs__tabs">
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          >
            <Tab label={"Chair_variant 1"}>
              <Tab
                imgUrl={"https://picsum.photos/200/300"}
                isSelectable
                label="Pose 1"
              />
            </Tab>
            <Tab label={"Chair_variant 2"}>
              <Tab
                imgUrl={"https://picsum.photos/200/300"}
                isSelectable
                label="Pose 1"
              />
              <Tab
                imgUrl={"https://picsum.photos/200/300"}
                isSelectable
                label="Pose 2"
              />
            </Tab>
          </Tab>
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
          <Tab
            imgUrl={"https://picsum.photos/200/300"}
            label={"Plastic kitchen chair"}
          />
        </div>
      </div>
    </div>
  );
}
