import React from "react";
import SecondaryButton from "../../Buttons/Secondary/SecondaryButton";

const ImageSection = () => (
  <div className="image-tab-content">
    <label>Image</label>
    <SecondaryButton className="upload-img-btn">Upload file</SecondaryButton>

    <div>
      <label>File</label>
      <span>...macbookpro.jpeg</span>
    </div>
    <SecondaryButton className={"delete-img-btn"}>Delete</SecondaryButton>
  </div>
);

export default function HotspotContentBottom({ activeTab }) {
  switch (activeTab) {
    case "image-tab":
      return <ImageSection />;

    default:
      return <></>;
  }
}
