import React from "react";
import ActionBar from "../../components/ActionBar/ActionBar";
import AddNewHotspot from "../../components/AddNewHotspot/AddNewHotspot";
import SearchInput from "../../components/Inputs/Search/SearchInput";
import ModelSettingsMenu from "../../components/ModelSettingsMenu/ModelSettingsMenu";
import ModelViewer from "../../components/ModelViewer/ModelViewer";
import NewVarientsSlider from "../../components/NewVarientsSlider/NewVarientsSlider";
import ProductsDrawer from "../../components/ProductsDrawer/ProductsDrawer";
import ProductsTabs from "../../components/ProductsTabs/ProductsTabs";
import "./ModelViewerScreen.scss";
export default function ModelViewerScreen() {
  return (
    <div className="model-view-container">
      <ActionBar />
      <ModelViewer />
      <ProductsDrawer>
        <div className="product-search-container">
          <SearchInput placeholder="Search Product" />
        </div>
        <ProductsTabs />
      </ProductsDrawer>
      <AddNewHotspot />
      <NewVarientsSlider />
      <ModelSettingsMenu />
    </div>
  );
}
