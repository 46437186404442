import "./SearchInput.scss";

import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";

import ErrorMessage from "../../Error/ErrorMessage";

export default function SearchInput({ error, placeholder }) {
  return (
    <div className="text-input text-input--search">
      <label className="text-input__inner">
        <input
          className="text-input__input"
          type={"text"}
          name="search"
          placeholder=" "
          formNoValidate={true}
          data-error={error && true}
        />
        <span className="text-input__placeholder">{placeholder}</span>
        <button type="button" className="toggle-show-search">
          <SearchIcon />
        </button>
      </label>
      <ErrorMessage message={error} />
    </div>
  );
}
