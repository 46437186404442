import React from "react";

export default function HotspotTab({ tabs = [], active, onActiveChange }) {
  return (
    <ul className="hotspot-popup__navigation">
      {tabs.map((tab) => {
        return (
          <li className={tab.id === active ? "active" : ""}>
            <button onClick={() => onActiveChange(tab?.id)}>
              {tab?.label}
            </button>
          </li>
        );
      })}
    </ul>
  );
}
