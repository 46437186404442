import "./ProductScreen.scss";
import PreviousScreen from "../../components/PreviousScreen/PreviousScreen";
import ProductDescription from "../../components/ProductDescription/ProductDescription";
import ProductDetail from "../../components/ProductDetail/ProductDetail";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import { getProductById } from "../../store/dashboard/actions";
import { selectDashboard } from "../../store/dashboard/reducer";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import OutlineButton from "../../components/Buttons/Outline/OutlineButton";

export default function ProductScreen() {
  const params = useParams();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const id = params?.id;

  const { product } = useSelector(selectDashboard);
  const stopLoader = () => {
    setLoader(false);
  };
  useEffect(() => {
    if (id) {
      setLoader(true);
      dispatch(getProductById(id, stopLoader));
    }
  }, [id, dispatch]);

  if (loader) {
    return (
      <div className="abs-center">
        <Loading />
      </div>
    );
  }
  return (
    <main className="product-main">
      <div className="top-header">
        <PreviousScreen href={"/products"} label="Back to Product list" />
      </div>

      <div className="products-header">
        <h3>View Product</h3>
      </div>
      <ProductDescription product={product} />
      {product?.variants.map((variant) => {
        return <ProductDetail variant={variant} />;
      })}

      <div className="add-variant-wrapper">
        <OutlineButton className="add-variant-btn">
          <PlusIcon /> Add variant
        </OutlineButton>
      </div>
    </main>
  );
}
