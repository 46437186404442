import React, { useEffect, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import "./Toggle.scss";
export default function Toggle({ Icon, children }) {
  const [showMenu, setShowMenu] = useState(false);
  const toggleShow = () => {
    setShowMenu(!showMenu);
  };
  const DOMToggleHander = (e) => {
    e.stopPropagation();
    if (e.target.id !== "toggle-Handler") {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", DOMToggleHander);
    return () => document.removeEventListener("click", DOMToggleHander);
  }, []);
  return (
    <div className="toggle-wrapper">
      <button onClick={toggleShow} id="toggle-Handler">
        {Icon}
      </button>
      <AnimatePresence>
        {showMenu && (
          <motion.div
            initial={{
              opacity: 0,
              transform: "translateX(-100%, -10%)",
            }}
            animate={{
              opacity: 1,
              transform: "translate(-100%, -20%)",
            }}
            exit={{
              opacity: 0,
              transform: "translate(-100%, 10%)",
            }}
            className="toggle-content"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
