import React, { useState } from "react";
import "./ProductsDrawer.scss";
import { ReactComponent as ChevronRightIcon } from "../../assets/icons/chevron-right.svg";

export default function ProductsDrawer({ children }) {
  const [show, setShow] = useState(false);

  return (
    <div className={`product-drawer ${show ? "show" : ""}`}>
      <div className="product-drawer__content">{children}</div>
      <div className="product-drawer__icon">
        <button onClick={() => setShow(!show)}>
          <ChevronRightIcon
            style={{
              transform: show ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </button>
      </div>
    </div>
  );
}
