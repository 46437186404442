import ActionButtonViewerState from "../Action/ActionButtonViewerState";
import { ReactComponent as eyeIcon } from "../../../assets/icons/eye.svg";

export default function ViewButton() {
  return (
    <ActionButtonViewerState
      label="View"
      Icon={eyeIcon}
      icon="fa-light fa-eye"
      action="view"
      active={true}
    />
  );
}
