import React, { useEffect, useState } from "react";
import "./FilterSelector.scss";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { AnimatePresence, motion } from "framer-motion";
export default function FilterSelector({ title, name }) {
  const [showOptions, setShowOptions] = useState(false);
  const toggleSelect = () => {
    setShowOptions(!showOptions);
  };

  const domToggleHandler = (e) => {
    e.stopPropagation();

    if (e.target.id !== "selector-Handler") {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", domToggleHandler);
    return () => document.removeEventListener("click", domToggleHandler);
  }, []);
  return (
    <div className="filter-wrapper">
      <h1>{title}:</h1>
      <button
        id="selector-Handler"
        htmlFor={name + "-selector"}
        onClick={toggleSelect}
        className="filter-btn"
      >
        <span>Last opened</span>
        <ChevronDown />
        <AnimatePresence>
          {showOptions && (
            <motion.div
              initial={{
                height: 0,
                opacity: 0,
                overflow: "hidden",
              }}
              exit={{
                height: 0,
                opacity: 0,
                overflow: "hidden",
              }}
              animate={{
                height: "auto",
                opacity: 1,
              }}
              transition={{
                duration: 0.2,
                ease: "easeInOut",
              }}
              className="filter-selector"
            >
              <ul>
                <li>Last opened</li>

                <li>Last modified</li>
                <li>Last created</li>
                <li>Status</li>
                <li>A-Z</li>
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </button>
    </div>
  );
}
