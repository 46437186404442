import routes from "../routes/routes";
import { appRoutes, authRoutes } from "../routes/routeData";
import { useDispatch, useSelector } from "react-redux";
import { Hub } from "aws-amplify";
import { useEffect } from "react";
import { getCurrentUserAws } from "../store/auth/actions";
import { useState } from "react";
import Loading from "./Loading/Loading";
export default function App() {
  const { user } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  const stopLoader = () => {
    setLoader(false);
  };
  useEffect(() => {
    dispatch(getCurrentUserAws(stopLoader));
    Hub.listen("auth", () => {
      dispatch(getCurrentUserAws(stopLoader));
    });
  }, [dispatch]);
  if (loader) {
    return (
      <div className="abs-center">
        <Loading />
      </div>
    );
  }
  return (
    <>
      {user && user?.challengeName !== "NEW_PASSWORD_REQUIRED"
        ? routes(appRoutes)
        : routes(authRoutes)}
    </>
  );
}
