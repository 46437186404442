import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";

import { Amplify } from "aws-amplify";
import { config } from "./config/cognitoConfig";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";

import "react-multi-carousel/lib/styles.css";
import "./base/reset.scss";
import "./base/mixins.scss";
import "./base/helpers.scss";
import "./base/typography.scss";
import "@google/model-viewer";
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

Amplify.configure(config);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
