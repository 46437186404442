import "./Header.scss";
import { ReactComponent as BarsIcon } from "../../assets/icons/bars-solid.svg";
import Logo from "../Logo/Logo";
import PersonIcon from "../PersonIcon/PersonIcon";

export default function Header({ handelClick }) {
  return (
    <div className="header-container">
      <Logo />
      <div className="header-content">
        <button onClick={handelClick} className="header-icon">
          <BarsIcon />
        </button>

        <PersonIcon source={"https://picsum.photos/200"} size={45} />
      </div>
    </div>
  );
}
