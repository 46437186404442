import DashboardApi from "./dashboardApi";
import { dashboardActions } from "./reducer";

const authApi = new DashboardApi();

export const getAllProducts = (cb) => async (dispatch) => {
  try {
    const objects = await authApi.getS3ObjectKeys();
    const products = await authApi.getProductsData(objects);
    dispatch(dashboardActions.getAllProducts(products));
    cb();
  } catch (error) {
    console.log(error);
    cb();
  }
};
export const getProductById = (id, cb) => async (dispatch) => {
  try {
    const product = await authApi.getProductDataById(id);

    dispatch(dashboardActions.getProduct(product));
    cb();
  } catch (error) {
    console.log(error);
    cb();
  }
};

export const getVariantModel =
  (variantId, urlId, cb) => async (dispatch, getState) => {
    const { product } = getState().dashboard;
    try {
      const key = `products/models/${product.uuid}/${variantId}/${urlId}`;
      const model = await authApi.getModelByKey(key);

      cb(model);
    } catch (error) {
      console.log(error);
      cb();
    }
  };

export const deleteProductById = (productId, cb) => async (dispatch) => {
  try {
    const datakey = `products/data/${productId}.json`;

    await authApi.deleteDataById(datakey);

    dispatch(dashboardActions.deleteProductById(productId));
    cb();
  } catch (error) {
    console.log(error);
    cb();
  }
};
