import "./CodeButton.scss";
import { useState } from "react";

import ActionButtonStandard from "../Action/ActionButtonStandard";
import { ReactComponent as CodeIcon } from "../../../assets/icons/code.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "../../Modal/Modal";
import PrimaryButton from "../Primary/PrimaryButton";
import CopySuccess from "./CopySuccess";

export default function ShareButton() {
  const [showModal, setShowModal] = useState();
  const [isCopied, setCopied] = useState(false);
  const text = `<iframe frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking; clipboard-write;" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share width="100%" height="750px" src="https://reybeam.reydar.com/?config_uuid=2a3d6cd0-cc49-4faf-beb8-851c41c7414e"></iframe>`;
  const onCopyHandle = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };
  return (
    <>
      <ActionButtonStandard
        className="share-button"
        onClick={() => setShowModal(true)}
        Icon={CodeIcon}
      />
      <Modal
        id={"shared-modal"}
        canShow={showModal}
        onClose={() => setShowModal(false)}
      >
        <div className="shared-modal-content">
          <h1>Export code</h1>
          <textarea value={text} />
          <CopySuccess show={isCopied} />
          <CopyToClipboard onCopy={onCopyHandle} text={text}>
            <PrimaryButton className={"shared-model-copy-btn"}>
              Copy <CopyIcon />
            </PrimaryButton>
          </CopyToClipboard>
        </div>
      </Modal>
    </>
  );
}
