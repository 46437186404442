import "./ActionBar.scss";

import FullscreenButton from "../Buttons/Fullscreen/FullscreenButton";

import ShareButton from "../Buttons/Share/ShareButton";
import ArButton from "../Buttons/Ar/ArButton";
import ExploreButton from "../Buttons/Explore/ExploreButton";
import ViewButton from "../Buttons/View/ViewButton";
import SelectButton from "../Buttons/Select/SelectButton";

import PrimaryButton from "../Buttons/Primary/PrimaryButton";
import CodeButton from "../Buttons/Code/CodeButton";
import Logo from "../Logo/Logo";

const ActionBar = () => {
  return (
    <div className="action-bar">
      <div className="action-bar__left">
        <Logo />
      </div>

      <div className="action-bar-list">
        <ViewButton />
        <SelectButton />
        <ExploreButton />

        <ArButton />
      </div>

      <div className="action-bar__right">
        <FullscreenButton />
        <ShareButton />
        <CodeButton />

        <PrimaryButton className="action-save-button" disabled>
          Save
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ActionBar;
