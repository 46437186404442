import ActionButtonViewerState from "../Action/ActionButtonViewerState";
import { ReactComponent as SelectIcon } from "../../../assets/icons/select.svg";

export default function SelectButton() {
  return (
    <ActionButtonViewerState
      label="Select"
      Icon={SelectIcon}
      action="select"
      active={false}
    />
  );
}
