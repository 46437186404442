import ActionButtonViewerState from "../Action/ActionButtonViewerState";
import { ReactComponent as ExploreIcon } from "../../../assets/icons/explore.svg";

export default function ArButton() {
  return (
    <ActionButtonViewerState
      label="Explore"
      Icon={ExploreIcon}
      action="explore"
    />
  );
}
