import "./Drawer.scss";

export default function Drawer({ children, className, navIsOpen }) {
  return (
    <>
      <nav
        className={`drawer ${navIsOpen ? "open" : ""}  ${
          className && className
        }`}
      >
        {children}
      </nav>
    </>
  );
}
