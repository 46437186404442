import "./Loading.scss";

export default function Loading() {
  return (
    <div className="loading-spinner" aria-label="Loading spinner">
      <span aria-hidden={true}></span>
      <span aria-hidden={true}></span>
      <span aria-hidden={true}></span>
      <span aria-hidden={true}></span>
    </div>
  );
}
