import "./PersonIcon.scss";

export default function Logo({ source, size, name }) {
  return (
    <button
      style={{
        width: size,
        height: "auto",
      }}
      className="icon-wrapper"
    >
      <img src={source} alt={name} />
    </button>
  );
}
