import React, { useEffect, useState } from "react";
import ActionButtonStandard from "../../Buttons/Action/ActionButtonStandard";
import { ReactComponent as VedioIcon } from "../../../assets/icons/uit_youtube.svg";
import { ReactComponent as ImageIcon } from "../../../assets/icons/bi_card-image.svg";
import { ReactComponent as TextAlignIcon } from "../../../assets/icons/text.alignleft.svg";
import { ReactComponent as ListBulletIcon } from "../../../assets/icons/list.bullet.svg";
import { ReactComponent as ClarityImageIcon } from "../../../assets/icons/clarity_image.svg";
import { ReactComponent as FileIcon } from "../../../assets/icons/file.svg";

const topIcons = [
  {
    className: "hotspot-popup__tabcontent__icon",
    name: "vedio-tab",
    Icon: VedioIcon,
  },

  {
    className: "hotspot-popup__tabcontent__icon",
    name: "image-tab",
    Icon: ImageIcon,
  },
  {
    className: "hotspot-popup__tabcontent__icon",
    Icon: TextAlignIcon,
    name: "text-align-tab",
  },
  {
    className: "hotspot-popup__tabcontent__icon",
    Icon: ListBulletIcon,
    name: "list-bullet-tab",
  },
  {
    className: "hotspot-popup__tabcontent__icon",
    Icon: ClarityImageIcon,
    name: "clarity-image-tab",
  },
  {
    className: "hotspot-popup__tabcontent__iconfile",
    Icon: FileIcon,
    name: "file-tab",
  },
];
export default function HotspotContentTop({ value, onChange }) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (value) {
      setActive(value);
    }
  }, [value]);

  const onChangehandler = (val) => {
    onChange?.(val);
    setActive(val);
  };
  return (
    <div className="hotspot-popup__tabcontent__top">
      {topIcons.map(({ Icon, name, className }) => {
        return (
          <ActionButtonStandard
            active={active === name}
            onClick={() => onChangehandler(name)}
            className={className}
            Icon={Icon}
          />
        );
      })}
    </div>
  );
}
