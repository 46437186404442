import React, { useEffect, useState } from "react";

import OutlineButton from "../Buttons/Outline/OutlineButton";
import ProductDetailTabs, {
  TabHeader,
  TabBody,
  TabFooter,
  Tab,
} from "../ProductDetailTabs/ProductDetailTabs";
import { useDispatch } from "react-redux";
import "./ProductDetail.scss";
import { getVariantModel } from "../../store/dashboard/actions";
import Loading from "../Loading/Loading";

const tabs = [
  {
    label: "3D Model",
    id: "3d-model",
  },
  {
    label: "Photo reference",
    id: "photo-reference",
  },
];
export default function ProductDetail({ variant }) {
  const dispatch = useDispatch();

  const [model, setModal] = useState("");
  const [loader, setLoader] = useState(false);
  const stoploader = (data) => {
    setLoader(false);
    setModal(data);
  };
  const downloadProductModel = (url) => {
    const anchorElement = document.createElement("a");
    anchorElement.href = url;
    anchorElement.click();
    anchorElement.remove();
  };
  const getModalHandler = (urlId) => () => {
    dispatch(getVariantModel(variant.uuid, urlId, downloadProductModel));
  };
  useEffect(() => {
    if (variant) {
      setLoader(true);
      dispatch(getVariantModel(variant.uuid, variant.glb, stoploader));
    }
  }, [variant, dispatch]);
  return (
    <div className="product-detail-wrapper">
      <div className="product-variant-top">
        <p> Name: {variant?.variant_name}</p>
        <div className="product-status">
          <p>{variant?.status}</p>
        </div>
      </div>
      <div className="product-detail-container">
        {/* Detail section left */}
        <div className="product-detail-left">
          <div className="product-model-container">
            {loader ? (
              <Loading />
            ) : (
              <model-viewer
                alt={variant?.variant_name}
                src={model}
                width={"100%"}
                heigth="auto"
                ar
                ar-modes="webxr scene-viewer quick-look"
                shadow-intensity="1"
                camera-controls
                touch-action="pan-y"
              ></model-viewer>
            )}
          </div>

          <div className="description-box">
            <p>Pose: {variant?.description}</p>
            <div className="download-buttons-container">
              <OutlineButton onClick={getModalHandler(variant.glb)}>
                Download .glb
              </OutlineButton>
              <OutlineButton onClick={getModalHandler(variant.usdz)}>
                Download .usdz
              </OutlineButton>
            </div>
          </div>
        </div>
        {/* Detail section right */}
        <div className="product-detail-right">
          <ProductDetailTabs tabs={tabs}>
            <Tab className="tab" dataTab="3d-model">
              <TabHeader>
                <p>Drag & Drop or browse to upload</p>
                <OutlineButton className="tab__cta">3D modal</OutlineButton>
                <div className="ext">
                  <p>FBX, OBJ, DAE, BLEND, STL</p>
                  <p>
                    You can also upload an archive like ZIP, RAR, or 7z,
                    containing your textures, materials, and mesh
                  </p>
                </div>
              </TabHeader>
              <TabBody>
                <div className="product-dimension">
                  <h6>Dimensions, cm</h6>
                  &nbsp;
                  <p>
                    W
                    <span>{variant?.dimensions?.width?.toFixed(2) || "0"}</span>
                    H
                    <span>
                      {variant?.dimensions?.height?.toFixed(2) || "0"}
                    </span>
                    D
                    <span>{variant?.dimensions?.depth?.toFixed(2) || "0"}</span>
                  </p>
                </div>
                <div className="product-dimension">
                  <h6>Weight, kg</h6>
                  &nbsp;
                  <p>0</p>
                </div>
                <div className="product-dimension">
                  <h6>SKU</h6>
                  &nbsp;
                  <p></p>
                </div>
                <div className="product-dimension">
                  <h6>UPC</h6>
                  &nbsp;
                  <p></p>
                </div>
                <div className="product-dimension">
                  <h6>ID</h6>
                  &nbsp;
                  <p></p>
                </div>
              </TabBody>
              <TabFooter>
                <div className="product-dimension">
                  <h6>Colour</h6>
                  &nbsp;
                  <p>Describe a colour</p>
                </div>
              </TabFooter>
            </Tab>
            <Tab className="tab" dataTab="photo-reference">
              <TabHeader>
                <p>Drag & Drop or browse to upload</p>
                <OutlineButton className="tab__cta">Photo</OutlineButton>
                <div className="ext">
                  <p>
                    Minimum 6 photos JPG, PNG, PDF, InDesign, Photoshop files
                  </p>

                  <p>FBX, OBJ, DAE, BLEND, STL</p>
                  <p>
                    You can also upload an archive like ZIP, RAR, or 7z,
                    containing your textures, materials, and mesh
                  </p>
                </div>
              </TabHeader>
              <TabBody>
                <div className="product-dimension">
                  <h6>Dimensions, cm</h6>
                  &nbsp;
                  <p>
                    W<span>0</span>H<span>0</span>D<span>0</span>
                  </p>
                </div>
                <div className="product-dimension">
                  <h6>Weight, kg</h6>
                  &nbsp;
                  <p>0</p>
                </div>
                <div className="product-dimension">
                  <h6>SKU</h6>
                  &nbsp;
                  <p></p>
                </div>
                <div className="product-dimension">
                  <h6>UPC</h6>
                  &nbsp;
                  <p></p>
                </div>
                <div className="product-dimension">
                  <h6>ID</h6>
                  &nbsp;
                  <p></p>
                </div>
              </TabBody>
              <TabFooter>
                <div className="product-dimension">
                  <h6>Colour</h6>
                  &nbsp;
                  <p>Describe a colour</p>
                </div>
                <div className="product-dimension">
                  <h6>Material</h6>
                  &nbsp;
                  <p>Describe a material</p>
                </div>
              </TabFooter>
            </Tab>
          </ProductDetailTabs>
        </div>
      </div>
    </div>
  );
}
