import React, { useState } from "react";
import "./Tab.scss";
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/chevron-down.svg";
import { motion } from "framer-motion";
import Checkbox from "../Inputs/Checkbox/Checkbox";
export default function Tab({
  children,
  label,
  imgUrl,
  isSelectable,
  isChecked,
  onSelect,
}) {
  const [expand, setExpand] = useState(false);

  const toggleExpandHandler = (e) => {
    setExpand(e.target.checked);
  };

  return (
    <div className="product-tab-wrapper">
      {isSelectable ? (
        <Checkbox checked={isChecked} name={label} onChange={onSelect} />
      ) : (
        <>
          <input
            type="checkbox"
            id={`tab-input-${label}`}
            hidden
            checked={expand}
            onChange={toggleExpandHandler}
          />
          <label
            htmlFor={`tab-input-${label}`}
            className={`tab-input__label ${expand ? "expand" : ""}`}
          >
            <ChevronDownIcon />
          </label>
        </>
      )}

      <div className="product-tab-content">
        <div className="product-tab-content__content">
          {imgUrl && <img src={imgUrl} alt={label} />}
          <p>{label}</p>
        </div>
        {expand && (
          <motion.div
            style={{
              transformOrigin: "top",
            }}
            initial={{
              transform: "scale(0)",

              opacity: 0,
            }}
            animate={{
              transform: "scale(1)",
              opacity: 1,
            }}
          >
            {children}
          </motion.div>
        )}
      </div>
    </div>
  );
}
