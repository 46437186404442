import React from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as DotsIcon } from "../../assets/icons/dots.svg";
import { deleteProductById } from "../../store/dashboard/actions";
import Toggle from "../Toggle/Toggle";
function ProductTableRow({ poster, name, id, status }) {
  const dispatch = useDispatch();
  const deleteProductHandler = () => {
    dispatch(deleteProductById(id, () => {}));
  };
  return (
    <tr>
      <td>
        <img width="50px" src={poster} alt="name of product" />
      </td>
      <td className="col-expand">
        <a href={`/products/${id}`}>{name}</a>
      </td>
      <td>
        <div className="product-status">
          <p>{status}</p>
        </div>
      </td>
      <td>
        <div className="product-action">
          <Toggle Icon={<DotsIcon />}>
            <div className="action-menu">
              <button onClick={deleteProductHandler}>Delete product</button>
            </div>
          </Toggle>
        </div>
      </td>
    </tr>
  );
}

export default ProductTableRow;
