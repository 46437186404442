import React, { Fragment, Suspense } from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/auth/reducer";
const FilterRoutes = ({ routes = [] }) => {
  const { user } = useSelector(selectAuth);

  return (
    <Suspense>
      <Routes>
        {routes?.map((route, i) => {
          const Layout = route.layout || Fragment;
          const Component = route.component || Fragment;
          return (
            <Route
              path={route.path}
              key={i}
              element={
                <Layout>
                  {route?.children ? (
                    <SubRoutes routes={route?.children} />
                  ) : (
                    <Component />
                  )}
                </Layout>
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to={user ? "/" : "/login"} />} />
      </Routes>
    </Suspense>
  );
};

const SubRoutes = ({ routes = [] }) => {
  return (
    <Suspense>
      <Routes>
        {routes?.map((route, i) => {
          const Component = route.component || Fragment;

          return <Route path={route.path} key={i} element={<Component />} />;
        })}
      </Routes>
    </Suspense>
  );
};
export default function routes(routes) {
  return <FilterRoutes routes={routes} />;
}
