import "./ShareButton.scss";
import { useState } from "react";
import ReactGA from "react-ga4";
import ActionButtonStandard from "../Action/ActionButtonStandard";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import Modal from "../../Modal/Modal";

export default function ShareButton() {
  const [showModal, setShowModal] = useState();

  function copyToClipboard() {
    navigator.clipboard.writeText("gfgfg");
    setShowModal(true);
    setTimeout(() => setShowModal(false), 1000);
  }

  function handleClick() {
    navigator.share
      ? navigator
          .share({
            title: "ReyViewer - Model",
            url: "bsdbasbd",
          })
          .catch(console.error)
      : copyToClipboard();

    ReactGA.event({
      category: "Action Button",
      action: "reybeam_share",
    });
  }
  return (
    <>
      <ActionButtonStandard
        className="share-button"
        onClick={handleClick}
        Icon={ShareIcon}
      />
      <Modal id={"shared-modal"} canShow={showModal}>
        <p>URL copied to clipboard</p>
      </Modal>
    </>
  );
}
