import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { selectModelViewer } from "../../store/modelViewer/reducer";
import InformationButton from "../Buttons/Information/InformationButton";
import SettingsButton from "../Buttons/Settings/SettingsButton";

import "./ModelSettingsMenu.scss";
export default function ModelSettingsMenu() {
  const { topAction } = useSelector(selectModelViewer);

  return (
    <AnimatePresence>
      {topAction === "view" && (
        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
          }}
          exit={{
            scale: 0,
          }}
          className="model-menu"
        >
          <InformationButton />
          <SettingsButton />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
