import React from "react";
import "./Pagination.scss";
import { ReactComponent as ChevronLeftIcon } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/icons/chevron-right.svg";

export default function Pagination({ totalPages, limit, onLimitChange }) {
  return (
    <div className="pagination-wrapper">
      <div className="per-page">
        <label>Rows per page:</label>
        <select
          value={limit}
          onChange={(e) => {
            onLimitChange(e.target.value);
          }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
        </select>
      </div>
      <div className="pages-information">
        <p>
          <span>1</span>-<span>{limit}</span>of<span>{totalPages}</span>
        </p>
      </div>
      <div className="action-container">
        <button>
          <ChevronLeftIcon />
        </button>
        <button>
          <ChevronRightIcon />
        </button>
      </div>
    </div>
  );
}
