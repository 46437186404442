import "./ActionButton.scss";

const ActionButtonStandard = ({
  label,
  Icon,
  handleClick,
  disabled,
  onClick,
  className,
  active,
}) => {
  return (
    <button
      className={
        "action-btn" +
        (disabled ? " disabled" : "") +
        (className ? ` ${className}` : "") +
        (active ? " active" : "")
      }
      onClick={handleClick || onClick}
    >
      {Icon && <Icon className="action-btn__icon" />}
      {label && <span className="action-btn__label">{label}</span>}
    </button>
  );
};

export default ActionButtonStandard;
