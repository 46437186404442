import ActionButtonStandard from "../Action/ActionButtonStandard";
import { ReactComponent as BiQuestionCircleIcon } from "../../../assets/icons/bi_question-circle.svg";

export default function InformationButton() {
  return (
    <>
      <ActionButtonStandard
        className="information-button"
        Icon={BiQuestionCircleIcon}
      />
    </>
  );
}
