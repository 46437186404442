import CompleteSignupScreen from "../auth/CompleteSignup/CompleteSignupScreen";
import ForgotPasswordScreen from "../auth/ForgotPassword/ForgotPasswordScreen";
import OverviewScreen from "../screens/Overview/OverviewScreen";
import ProductsScreen from "../screens/Products/ProductsScreen";
import ProductScreen from "../screens/Product/ProductScreen";
import ConfiguratorScreen from "../screens/Configurator/ConfiguratorScreen";

import LoginScreen from "../auth/Login/LoginScreen";
import SignupScreen from "../auth/Signup/SignupScreen";
import NewProductScreen from "../screens/NewProduct/NewProductScreen";
import AppLayout from "../layouts/App/AppLayout";

import ModelViewerScreen from "../screens/ModelViewer/ModelViewerScreen";

export const authRoutes = [
  {
    path: "/login",
    component: LoginScreen,
  },
  {
    path: "/signup",
    component: SignupScreen,
  },
  {
    path: "/forgot-password",
    component: ForgotPasswordScreen,
  },
  {
    path: "/complete-signup",
    component: CompleteSignupScreen,
  },
];

export const appRoutes = [
  {
    path: "/",
    layout: AppLayout,
    component: OverviewScreen,
  },
  {
    path: "/products/*",
    layout: AppLayout,

    children: [
      {
        path: "/",
        component: ProductsScreen,
      },
      {
        path: "/:id",
        component: ProductScreen,
      },
      {
        path: "/new",
        component: NewProductScreen,
      },
    ],
  },
  {
    path: "/configurator/*",
    layout: AppLayout,

    children: [
      {
        path: "/",
        component: ConfiguratorScreen,
      },
      {
        path: "/:id",
        component: ModelViewerScreen,
      },
      {
        path: "/new",
        component: NewProductScreen,
      },
    ],
  },
  {
    path: "/model-viewer",
    component: ModelViewerScreen,
  },
];
