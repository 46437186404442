import { Auth } from "aws-amplify";

class AuthApi {
  getCurrentUser = async () => {
    return await Auth.currentAuthenticatedUser();
  };
  userLogin = async (email, password) => {
    return await Auth.signIn(email, password);
  };
}

export default AuthApi;
