/* https://docs.amplify.aws/lib/auth/start/q/platform/js/ */

export const config = {
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_qpdJJ5PwW",
    userPoolWebClientId: "5rm8ft6vkpo3b0c7fr7n96tcfs",
    identityPoolId: "us-east-1:582c9456-0c1d-4662-bf37-e1ee9c202c08",
    authenticationFlowType: "USER_SRP_AUTH",
    mandatorySignIn: true,
  },
  Storage: {
    AWSS3: {
      bucket: "cdn.enginecloud.co.uk",
      region: "eu-west-1",
    },
  },
};
