import React, { useState } from "react";
import Tag from "../Tag/Tag";
import "./Tags.scss";
export default function Tags({ tags, onInputChange }) {
  const [inputTag, setInputTag] = useState("");
  const inputChangeHandle = (e) => {
    setInputTag(e.target.value);
  };
  const enterHandler = (e) => {
    if (e.key === "Enter" && inputTag) {
      onInputChange(e);
      setInputTag("");
    }
  };
  return (
    <div className="tags-container">
      {tags?.length > 0 && (
        <>
          {tags.map((tag) => (
            <Tag tag={tag} key={tag.id} />
          ))}
        </>
      )}

      <input
        type={"text"}
        value={inputTag}
        onKeyDown={enterHandler}
        onChange={inputChangeHandle}
      />
    </div>
  );
}
