import { useState } from "react";

import "./Hotspot.scss";
import HotspotContent from "./HotspotContent";

export default function Hotspot({ hotspot }) {
  const position = `${hotspot?.position.x} ${hotspot?.position.y} ${hotspot?.position.z}`;
  const normal = `${hotspot?.normal.x} ${hotspot?.normal.y} ${hotspot?.normal.z}`;
  const [showContent, setShowContent] = useState(false);
  return (
    <button
      onClick={() => setShowContent(!showContent)}
      className="hotspot"
      data-normal={normal}
      data-position={position}
      slot={`hotspot-${position}`}
      data-visibility-attribute="visible"
    >
      <HotspotContent
        show={showContent}
        onClose={() => setShowContent(false)}
      />
    </button>
  );
}
