import ActionButtonStandard from "../Action/ActionButtonStandard";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import "./SettingsButton.scss";
import { useState } from "react";
import SettingsPopup from "./SettingsPopup";
export default function SettingsButton() {
  const [showSettings, setShowSettings] = useState(false);
  return (
    <div className="model-settings">
      <ActionButtonStandard
        className="model-settings__button"
        onClick={() => setShowSettings(!showSettings)}
        Icon={SettingsIcon}
      />

      <SettingsPopup
        show={showSettings}
        onClose={() => setShowSettings(false)}
      />
    </div>
  );
}
