import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import SelectWithLabel from "../../Inputs/SelectWithLabel/SelectWithLabel";
import SecondaryButton from "../Secondary/SecondaryButton";
import { ReactComponent as SelectIcon } from "../../../assets/icons/select.svg";
import { ReactComponent as ExploreIcon } from "../../../assets/icons/explore.svg";

import { ReactComponent as ArIcon } from "../../../assets/icons/ar.svg";

export default function SettingsPopup({ show, onClose }) {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{
            transform: "translateY(-100%) scale(0)",
          }}
          animate={{
            transform: "translateY(-105%) scale(1)",
          }}
          exit={{
            transform: "translateY(-100%) scale(0)",
          }}
          className="model-settings__popup"
        >
          <div className="model-settings__popup__header">
            <h1>3d viewer settings</h1>
            <button onClick={onClose}>
              <CloseIcon />
            </button>
          </div>

          <div className="model-settings__popup__body">
            <div className="model-settings__popup__body__size">
              <h2>3D viewer size, px</h2>
              <div className="area-box">
                <span>W 32</span>
                <span>h 32</span>
              </div>
            </div>
            <div className="model-settings__popup__body__size">
              <SelectWithLabel
                label={"Bg color"}
                name={"Bg color"}
                type="colorPicker"
              />
              <SelectWithLabel
                label={"HDRI image"}
                name={"HDRI image"}
                type="switch"
              />
            </div>
            <div className="model-settings__popup__body__size">
              <h2>Logo</h2>

              <SelectWithLabel
                label={"Show Reydar Logo"}
                name={"Show Reydar Logo"}
                type="switch"
              />
              <h2>Your Logo</h2>

              <SecondaryButton className={"upload-img-btn"}>
                Upoad file
              </SecondaryButton>
            </div>

            <div className="model-settings__popup__body__size">
              <SelectWithLabel
                label={"Lighting"}
                name={"Lighting"}
                type="select"
              />
            </div>
            <div>
              <SelectWithLabel
                Icon={SelectIcon}
                label={"Select"}
                name={"Select"}
                type="switch"
              />
              <SelectWithLabel
                Icon={ExploreIcon}
                label={"Explore"}
                name={"Explore"}
                type="switch"
              />

              <SelectWithLabel
                Icon={ArIcon}
                label={"AR"}
                name={"AR"}
                type="switch"
              />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
