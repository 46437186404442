import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  products: [],
  topAction: "view",
};

const modelViewer = createSlice({
  name: "modelViewer",
  initialState,
  reducers: {
    updateTopAction: (state, action) => {
      state.topAction = action.payload;
    },
  },
});

export const modelViewerActions = modelViewer.actions;
export const selectModelViewer = (state) => state.modelViewer;
export default modelViewer.reducer;
