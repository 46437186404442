import "./Navigation.scss";

import Drawer from "../Drawer/Drawer";

import DrawerItem from "../Drawer/Item/DrawerItem";

import { ReactComponent as OverviewIcon } from "../../assets/icons/home.svg";
import { ReactComponent as ProductsIcon } from "../../assets/icons/folder.svg";
import { ReactComponent as ConfigureIcon } from "../../assets/icons/box.svg";

export default function Navigation({ navIsOpen, handelClick }) {
  return (
    <Drawer className="navigation" navIsOpen={navIsOpen}>
      <ul className="navigation__top">
        <DrawerItem
          onClick={handelClick}
          label="Overview"
          icon={<OverviewIcon />}
          route="/"
        />
        <DrawerItem
          onClick={handelClick}
          label="Products"
          icon={<ProductsIcon />}
          route="/products"
        />
        <DrawerItem
          onClick={handelClick}
          label="3D Viewer"
          icon={<ConfigureIcon />}
          route="/configurator"
        />
      </ul>
    </Drawer>
  );
}
