import AuthApi from "./authApi";
import { authActions } from "./reducer";

const authApi = new AuthApi();
export const getCurrentUserAws = (cb) => async (dispatch) => {
  try {
    const user = await authApi.getCurrentUser();

    dispatch(authActions.setUser(user));
    cb();
  } catch (error) {
    cb();
    console.log(error);
  }
};
export const userLogin =
  ({ email, password }, cb) =>
  async (dispatch) => {
    try {
      const user = await authApi.userLogin(email, password);
      cb(user);
    } catch (error) {
      cb(error);
      console.log(error);
    }
  };
