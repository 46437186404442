import "./PreviousScreen.scss";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrow-left.svg";

export default function PreviousScreen({ href, label }) {
  return (
    <Link to={href} className="back-to-home">
      <ArrowLeftIcon />
      <span>{label}</span>
    </Link>
  );
}
